<template>
   <div class="index-wrapper">
    <div class="flex-box">
        <div class="hd">
            <div class="index-banner">
                <van-swipe :autoplay="8000" indicator-color="#fff" ref="indexBanner" :options="swiperOptionBanner">
                    <van-swipe-item v-for="(image, index) in indexData.banners" :key="index">
                        <img v-lazy="image.url">
                    </van-swipe-item>
                </van-swipe>
            </div>
        </div>
        <div class="bd" ref="indexScrollView">
            <!-- 通知消息预留 -->
            <div class="system-message" v-if="indexData.systemMessage"></div>
            <!-- 导航列表 -->
            <div class="nav-list index-nav">
                <van-grid :border="false">
                    <van-grid-item v-for="item in menuList" :key="item.path" :icon="item.icon" class="big" :text="item.text"  :to="{path:item.path}"/>
                </van-grid>
            </div>
        </div>
    </div>
   </div>
</template>

<script>

// import api from "@/api";

import 'swiper/swiper-bundle.css';

export default {

    data(){

        return{
            
            swiperOptionBanner:{

                // 修改swiper自己或子元素时，自动初始化swiper
                observer: true,
                // 修改swiper的父元素时，自动初始化swiper
                observeParents: true,

                autoplay:8000

            },

            indexData:{

                banners:[

                    {
                        aid: 5,
                        areaId: "haikou",
                        id: 3657,
                        name: "welcome",
                        url: process.env.VUE_APP_SYSTEM_NAME == 'huimai' ? require('@/assets/images/welcome_hm.jpg') :  require('@/assets/images/welcome.jpg')
                    }

                ]
            }

        }

    },

    computed:{

        menuList(){

            return this.$store.state.userInfo?.menuList || [];

        }

    },

    methods:{

        goRouter(router){

            this.$router.push(router);

        }

    }

}
</script>

<style lang="less">
.index-wrapper{

.md-activity-indicator-rolling .rolling-container{

    top:1px;

}

.flex-box{

    height:100%;

    overflow: hidden;

    overflow-y:auto;

    .hd{

        box-shadow:0 2px 5px rgba(0,0,0,.06);

        position: relative;

          .index-banner{

            width: 100%;

            height:190px;

            overflow: hidden;

            img{

                width: 100%;

                height:100%;

            }

            .van-swipe{

                height:100%;

            }

        }

    }

    .bd{

        height:0;

        -webkit-overflow-scrolling:touch;

        border-top:10px solid #f1f1f1;

        .bd-scroll-view{

            min-height:100%;

            padding:15px 18px 20px;

        }

       
      

        .index-nav{

            margin-bottom:10px;

            padding: 0 10px;

            .van-grid{
                align-items: flex-start;
            }

            .van-grid-item{

                .van-grid-item__content{

                    padding-bottom:10px;

                }

                &:nth-child(5n+1){

                    .van-grid-item__icon{


                        background-image: linear-gradient(to right,#f7b166 , #ffa53d);

                    }

                }                
                
                &:nth-child(5n+2){

                    .van-grid-item__icon{


                        background-image: linear-gradient(to right,#5ae489  , #39cb7d);

                    } 

                }               
                
                &:nth-child(5n+3){

                    .van-grid-item__icon{


                        background-image: linear-gradient(to right,#6bc6f5  , #3cb4fb);

                    } 

                }                
                
                &:nth-child(5n+4){

                    .van-grid-item__icon{

                        background-image: linear-gradient(to right,#f7734e  , #fc4727);

                    } 

                }

                 &:nth-child(5n+5){

                    .van-grid-item__icon{


                        background-image: linear-gradient(to right,#6bc6f5  , #3cb4fb);

                    } 

                }    


                &.big{

                    .van-grid-item__icon{

                        font-size:26px;

                    }

                }

                .van-grid-item__icon{

                    color:#fff;

                    display: block;

                    width: 50px;

                    height:50px;

                    border-radius:50%;

                    overflow: hidden;

                    box-shadow: 0 0 3px rgba(0,0,0,.1);

                    text-align: center;

                    line-height:50px;

                    font-size:20px;

                }

                .van-grid-item__text{

                    color:#6c6c6c;

                    font-size:12px;

                }


            }

        }

        .index-column{

            margin-bottom:30px;

            .index-column__hd{

                .index-column__hd-title{

                    color:#333333;

                    font-size:18px;

                    font-weight: bold;

                    display: flex;

                    justify-content: space-between;
                    
                    align-items: center;

                    .index-column__hd-title__more{

                        font-size:18px;

                        font-weight: bold;

                        color:#666666;

                    }

                }

                .index-column__hd-title_sub{

                    line-height:28px;

                    color:#888888;

                }

            }

            .index-column__bd{

                padding-top:10px;

                &.free-swiper{

                    .swiper-container{

                        overflow: visible;

                    }

                    .swiper-slide{

                        width: auto;

                        margin-right:10px;

                        .item{

                            width: 156px;                     

                            .item-thumb-cover{

                                width: 100%;    

                                height:101px;

                                overflow: hidden;

                                 border-radius: 5px;
                               

                                img{

                                    width: 100%;

                                    min-height:100%;

                                }

                            }

                            .item-content{

                                padding-top:10px;

                                p{

                                    line-height:20px;

                                    &.name{

                                        font-size:15px;

                                    }

                                    &.tags{

                                        padding-top:3px;

                                        display: flex;

                                        min-height:20px;

                                        span{

                                            padding-right:4px;

                                            margin-right:4px;

                                            color:#888;

                                            position: relative;

                                            &:after{

                                                content:'';

                                                position: absolute;

                                                right:0;

                                                top:50%;

                                                width: 1px;

                                                height:50%;

                                                transform: translateY(-60%);

                                                background:#888;

                                            }

                                            &:last-child:after{

                                                display: none;

                                            }

                                        }

                                    }

                                    &.price{

                                        color:#f58d3d;

                                        font-size:15px;

                                        padding-top:3px;

                                    }

                                }

                            }

                        }

                    }

                }

            }

        }

        .skeleton{

            .index-banner{

                background: #f6f6f6;

            }

            .index-nav{

                .van-grid-item .van-grid-item__icon{

                    background:#f6f6f6;

                    color:#f6f6f6;

                }

            }

        }

    }

}
    

}
</style>
