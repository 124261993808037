
/**
 * 
 *报备详情路由，所有报备详情相关写在此处
 * 
 *  
 * 
 * 
 * 
 * 注：此模块路由全部为异步路由
 * 
 * 
 * 
 */

const PERMISSION = 'RECORDDETAIL';

//是否为公共模块

const isCommon = true;


const router = [

    {

        path:'/record/detail/:id',

        name:'record_detail',

        component:() => import(/* webpackChunkName: "record_detail" */ '@/views/record/record_detail'),

        meta:{

            lv:5,

            module:true,

            permission:PERMISSION,

            common:isCommon

        }

    },
    {

        path:'/record/audit/:id',

        name:"record_audit",

        component:() => import(/* webpackChunkName: "record_audit" */ '@/views/record/record_audit'),

        meta:{

            lv:6,

            module:true,

            permission:PERMISSION,

            common:isCommon

        }


    }

];

export default router;