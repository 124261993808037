<template>
    <div class="login-wrapper">
        <div class="scroll-view">
            <div class="flex-cover top">
                <div class="logo"><img :src="logoShow" :alt="systemName"></div>
                <div class="title">
                    <p class="main-color_bg">{{ systemName }}管理系统</p>
                </div>
            </div>
            <div class="login-form">
                <van-field v-model="username" name="username" placeholder="请填写用户名" autocomplete="off" clearable
                    ref="loginUsername" @keydown.enter.native="loginStart">
                    <template #left-icon>
                        <van-icon name="system-username" class="login-icon" />
                    </template>
                </van-field>
                <van-field v-model="password" center ref="codeFocus" placeholder="短信验证码" @keydown.enter.native="loginStart">
                    <template #left-icon>
                        <van-icon name="system-password" class="login-icon base" />
                    </template>
                    <template #button>
                        <van-button size="mini" type="info" :disabled="true" v-if="countdown">{{ num }}秒后可重新获取</van-button>
                        <van-button type="info" size="mini" @click="loginStart" :disabled="loading" v-else>{{ isInit ?
                            "重新获取" : "获取验证码" }}</van-button>
                    </template>
                </van-field>
                <div class="login-btn" @click="login">登录</div>
            </div>
        </div>
        <verify-code
        v-model="verifyCodeShow"
        @confirm="getCheckCode"
        ref="verifyCode"
        :loading="codeLoading"
        ></verify-code>
    </div>
</template>

<script>
import dataStorage from "@/utils/dataStorage";
import api from "@/api";
import {isPhone} from "@/utils" 
import verifyCode from "@/components/common/verify-code";
export default {
    components:{
        verifyCode
    },
    data() {

        return {
            username: '',
            password: '',
            checked: false,
            passwordShow: false,
            logo: require('@/assets/images/login_logo.png'),
            code: '',
            verifyCodeShow: false,
            //是否在倒计时
            countdown: false,
            //倒计时秒数
            countdownSecond: 60,
            num: 0,
            codeLoading: false,
            inv_id: null,
            loading: false,
            isInit: false,
        }


    },


    computed: {

        backRouteName() {

            return this.$store.state.userInfo.backRouter;

        },

        logoShow() {

            return this.logo;

        },

        systemName() {

            return '房小云'

        }

    },

    methods: {
        selected() {
            //每次都要储存状态
            dataStorage.StorageSetter('savePassword', this.checked)
        },
        loginStart() {
            if (!this.username) {
                this.$toast({
                    message: '用户名不能为空',
                });
                this.$refs.loginUsername.focus();
                return;
            }
            if (!isPhone(this.username)) {
                this.$toast({
                    position: "bottom",
                    message: "手机号格式不正确",
                });
                this.$refs.loginUsername.focus();
                return;
            }
            //每次获取都拉起机器验证码
            this.verifyCodeShow = true;
        },
        login() {
            if (!this.username) {
                this.$toast({
                    message: '用户名不能为空',
                });
                this.$refs.loginUsername.focus();
                return;
            }
            if (!this.password) {
                this.$toast({
                    message: '短信验证码不能为空',
                });
                this.$refs.codeFocus.focus();
                return;
            }
            this.$toast.loading({
                duration: 0,
                message: '登录中...',
                forbidClick: true,
                cancelButtonColor: "#00a0e4"
            });
            api.signLogin({
                options: {
                    username: this.username,
                    password: this.password,
                },
                success: (res) => {
                    if (res.type == 200) {
                        this.$store.commit('initData', res.data);
                        this.$nextTick(() => {
                            this.$router.replace({ name: "home" });
                        });
                        //提示一下当前登录的公司
                        if (res.data.userCompany?.companyName) {
                            this.$toast({
                                message: `当前登录公司为：${res.data.userCompany?.companyName}`,
                                position: 'bottom',
                                duration: 3000
                            })
                        }
                    } else {
                        this.$dialog.alert({
                            title: '登录失败',
                            message: res.msg || '登陆失败，原因不明，请联系管理员'
                        })
                    }
                },
                error: (err) => {
                    console.log(err);
                    this.$dialog.alert({
                        title: '登陆失败',
                        message: `登录异常，请稍后重试,${err}`
                    })
                },
                complete: () => {
                    this.$toast.clear();
                }
            })
        },
        getCheckCode(verifyCode) {
            this.loading = true;
            this.codeLoading = true;
            api.getCode({
                options: {
                    username: this.username,
                    ...verifyCode,
                },
                success: (res) => {
                    if (res.type == 200) {
                        this.verifyCodeShow = false;
                        this.$toast({
                            message: "已成功发送验证码",
                            position: "bottom",
                        });
                        this.countdownStart();
                        this.isInit = true;
                        this.status = "checkCode";
                        //聚焦
                        this.$nextTick(() => {
                            this.$refs.codeFocus.focus();
                        });
                    } else {
                        this.$toast(res.msg);
                        this.$refs.verifyCode?.getVerifyCode &&
                            this.$refs.verifyCode?.getVerifyCode();
                    }
                },
                error: (err) => {
                    console.log(err);
                    this.$refs.verifyCode?.getVerifyCode &&
                        this.$refs.verifyCode?.getVerifyCode();
                },
                complete: () => {
                    this.loading = false;
                    this.codeLoading = false;
                },
            });
        },
        //开始倒计时
        countdownStart() {
            //开始倒计时
            this.countdown = true;
            this.num = this.countdownSecond;
            this.num--;
            this.inv_id = setInterval(() => {
                this.num--;
                if (!this.num) {
                    this.countdown = false;
                    clearInterval(this.inv_id);
                }
            }, 1000);
        },
        //停止倒计时
        countdownEnd() {
            clearInterval(this.inv_id);
            this.countdown = false;
            this.num = 0;
        },
        userNameChange(name, value) {

            if (value == '') {

                this.userNameError = '用户名不能为空';

            } else {

                this.userNameError = '';

            }

        },
        passwordChange(name, value) {
            if (value == '') {
                this.passwordError = '密码不能为空';
            } else {
                this.passwordError = '';
            }
        }
    }

}
</script>

<style lang="less">
.login-wrapper {

    height: 100%;

    .scroll-view {

        height: 100%;

        overflow: hidden;

        overflow-y: scroll;

        padding-bottom: 30px;

    }

    .top {

        display: flex;

        flex-direction: column;

        justify-content: center;

        align-items: center;

        min-height: 30vh;

    }

    .logo {

        width: 200px;

        height: 200px;

        position: relative;

        left: -5px;

        img {

            width: 100%;

            height: 100%;



        }

    }

    .m-10 {

        margin-top: 10px;

    }

    .title {

        text-align: center;

        p {

            display: inline-block;

            padding: 6px 20px;

            font-size: 16px;

            color: #ffffff;

            border-radius: 25px;

            &.main-color_bg_hm {
                background: #ffae00;
            }

            &.main-color_bg {
                background: #00a0e4;
            }

        }

    }

    .login-form {

        padding: 0 30px;

        margin-top: 30px;

        .form-icon {

            font-size: 22px;

        }

    }

    .login-btn {

        height: 40px;

        line-height: 40px;

        text-align: center;

        font-size: 20px;

        color: #ffffff;

        background-image: linear-gradient(to right, #00a0e4, #0471ff);

        border-radius: 25px;

        margin-top: 30px;

        transition: opacity .3s ease;

        &.huimai {

            background-image: linear-gradient(to right, #ffcb5b, #ffae00);

        }


        &:active {

            opacity: .6;

        }

    }

    .login-icon {

        font-size: 22px;

        margin-right: 10px;

    }

    .van-cell {

        font-size: 16px;

        .van-field__control {

            padding-top: 3px;

        }

    }

    .toolbar {

        display: flex;

        justify-content: center;

        padding-top: 15px;

        .item {

            font-size: 13px;

            color: #00a0e4;

            padding: 0 10px;

            opacity: .8;

        }


    }

    .login-save {

        padding: 10px 15px 0;

        .item {

            color: #00a0e4;

            font-size: 13px;

        }

        .van-checkbox__icon {

            font-size: 16px;

        }

        .van-checkbox__label {

            font-size: 14px;

            position: relative;

            color: #666;


        }

        .active .van-checkbox__label {

            color: #00a0e4;

        }

    }

}
</style>
