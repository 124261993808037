export default {
    
    /*************** 用户相关接口 ***************/

    //登录

    login:{type:"post",url:"/api/baobei-login/sign"},

    //短信登录
    signLogin:{type:'post',url:'/api/baobei-login/sign-by-code'},

    //退出登录

    logOut:{type:"post",url:"/api/baobei-login/logout"},

    //更新用户数据

    updateUserData:{type:"post",url:'/api/baobei/update-info'},

    //获取机器验证码

    getVerifyCode:{type:'get',url:"/api/baobei-login/verify-code"},

    //获取短信验证码

    getCode:{type:'get',url:"/api/baobei-login/get-code"},

    //密码修改

    changePassword:{type:'post',url:"/api/baobei/change-password"},

    //密码找回

    retrievePassword:{type:'post',url:'/api/baobei-login/retrieve-password'},

    //检查登录状态

    getInitConfig:{type:"post",url:"/api/baobei-login/check-token"},

    //获取未登录时的公共数据

    getCommonInitData:{type:'post',url:"/mapi/index/getInfo.html"},

    //提交反馈信息

    help:{type:"post",url:"/mapi/user/addGbook.html"},

    //获取微信验证码

    getWxCode:{type:'post',url:"/mapi/waplogin/getWxCode"},

    //获取微信地址

    bindWx:{type:"post",url:'/api/baobei/wx-login'},

    //绑定微信code

    bindWxCode:{type:"get",url:'/api/baobei/oauth-callback'},

    //解绑微信

    closeWx:{type:"get",url:'/api/baobei/wechat-out'},

    //获取微信接口参数

    getWxConfig:{type:'post',url:'/wechat/index/getJsSdk.html'},

    //获取公司列表

    getCompanyList:{type:'post',url:'/api/baobei/get-company'},

    //选择公司
    
    selectCompany:{type:'post',url:'/api/baobei/select-company'},
    

    
    /*************** 公共的接口 ***************/


    //上传图片

    upload:{type:'post',url:'/api/upload/index'},

    
    //获取楼盘列表

    getHouseList:{type:"get",url:'/api/baobei/get-house-list',list:true},

    //获取楼盘详情

    getHouseData:{type:"get",url:'/api/building/detail'},

    //对楼盘数据进行更新

    updateHouseData:{type:'post',url:"/api/building/edit"},

    //新增楼盘

    createHouseData:{type:'post',url:'/api/building/add'},

    //提交前楼盘校验
    
    checkBuildingRepeat:{type:'get',url:"/api/building/check-building-repeat"},

    //收藏楼盘

    favoriteHouse:{type:'post',url:"/mapi/index/favoriteHouse.html"},

    //获取收藏列表

    favoriteHouseList:{type:'post',url:"/mapi/index/favoriteHouseList.html"},



    /*************** 首页数据接口 *************/


    getIndexData:{type:"get",url:"/mapi/index/getIndexData.html"},


    /*************** 报备列表接口 ***************/

    //获取渠道报备单列表

    getChannelRecordList:{type:"post",url:'/api/baobei/get-channel-record-table-h5',list:true},

    //获取拓展报备单列表

    getExtensionRecordList:{type:"post",url:"/api/baobei/get-extension-record-table-h5",list:true},

    //智能识别接口

    getDemoMessage:{type:"post",url:"/mapi/publicapi/getDemoMessage.html"},

    //提交报备信息

    submitRecordData:{type:'post',url:'/mapi/record/postRecordData.html'},

    //获取报备列表

    getRecordList:{type:'post',url:'/api/baobei/get-record-table-h5',list:true},

    //获取公司报备列表

    getSubDistributorRecordList:{type:'post',url:'/mapi/record/getSubDistributorRecordList.html'},

    //上传报备图片

    uploadRecordImage:{type:'post',url:'/api/baobei/upload-record-image'},

    //删除报备图片

    removeRecordImage:{type:'post',url:'/api/baobei/remove-record-image'},

    //客户动态

    updateRecordCustomerInfo:{type:'post',url:'/mapi/record/updateRecordCustomerInfo.html'},


    //获取报备单数据

    getRecordData:{type:'post',url:"/api/baobei/get-record-data-h5"},

    //报备单审核数据获取地址

    getRecordAuditData:{type:'post',url:'/api/baobei/get-record-audit-data-h5'},

    //报备单审核提交

    submitRecordAuditData:{type:'post',url:'/api/baobei/submit-record-audit-data'},

    //获取客户列表
    
    getCustomerList:{type:"post",url:"/mapi/record/getCustomerList.html"},

    //删除客户

    removeCustomer:{type:"post",url:'/mapi/record/removeCustomer.html'},

    //员工管理列表

    getStaffList:{type:"post",url:"/mapi/user/getStaffList.html"},

    //删除员工

    removeStaff:{type:"post",url:'/mapi/user/removeStaff.html'},

    //添加员工

    addStaff:{type:"post",url:'/mapi/user/addStaff.html'},

    //获取经理人下拉菜单列表

    getStaffSelectList:{type:'post',url:'/mapi/user/getStaffSelectList.html'},




    /****************** 渠道口数据  *************/

    //获取渠道统计首页数据

    getChannelReportStatistics:{type:'post',url:"/api/baobei/get-report-statistics"},

    //获取分销商统计详情数据

    getDistributorToReportStatistics:{type:"post",url:"/api/baobei/get-distributor-to-report-statistics",list:true},

    //获取渠道口统计详情

    getChannelToReportStatistics:{type:"post",url:"/api/baobei/get-channel-to-report-statistics",list:true},

    //获取渠道分组列表

    getChannelSelectList:{type:"post",url:"/api/baobei/get-channel-select-list"},




    /**************** 拓展口数据  *****************/


    //获取拓展统计首屏数据

    getExtensionToReportStatistics:{type:"post",url:"/api/baobei/get-extension-statistics"},

    //获取楼盘拓展数据

    getHouseToReportStatistics:{type:"post",url:"/api/baobei/get-house-to-report-statistics",list:true},

    //获取拓展数据

    getExtensionToReportStatisticsa:{type:"post",url:"/api/baobei/get-extension-to-report-statistics"},

    //获取拓展选项数据

    getExtensionSelectList:{type:'post',url:"/api/baobei/get-extension-select-list"},

    //获取对应拓展人员的楼盘数据

    getExtensionHouseStatistics:{type:'post',url:"/api/baobei/get-extension-house-statistics"},










    /******** 分销商管理入口 ***********/

    //获取分销商总数

    getSubdistributorNum:{type:'get',url:'/api/baobei/get-subdistributor-num'},


    //分销商列表

    getSubdistributorTableData:{type:"post",url:"/api/baobei/get-subdistributor-table-data-h5",list:true},

    //分销商详情

    getSubdistributorDetail:{type:"post",url:"/api/baobei/get-subdistributor-detail-h5"},



    //获取分销商申请列表

    getSubdistributorCheckData:{type:'post',url:"/api/baobei/get-subdistributor-check-data-h5",list:true},

    //获取分销商申请数据详情

    getSubdistributorCheckDetail:{type:'post',url:"/api/baobei/get-subdistributor-check-detail-h5"},



    //提交分销商审核数据
    
    setSubdistributorCheckData:{type:"post",url:"/api/baobei/set-subdistributor-check-data-h5"},



    //报备专员审核入口
    
    getRecordAuditList:{type:'post',url:"/api/baobei/get-record-table",list:true}



}