
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "@/assets/css/common.less";
import "@/assets/icon/iconfont.css";
import lazyImage from "@/assets/images/loading.png";
/**
 * 
 * 切记！！！
 * 
 * 使用这个插件之前请前往 vue-page-stack common 1578行
 * 
 * 将 stack[stack.length - 1].vnode.componentInstance.$destroy(); 修改为
 * 
 * stack[stack.length - 1] && stack[stack.length - 1].vnode && stack[stack.length - 1].vnode.componentInstance.$destroy();
 * 
 */
import VuePageStack from '@/utils/dist/vue-page-stack.common';
import eventBus from "@/eventBus";
import VueClipboard from 'vue-clipboard2';
import { Tabbar,TabbarItem, Button,Search,PullRefresh,Toast, Swipe, SwipeItem,Lazyload,Grid, GridItem,Icon,Cell,CellGroup,NavBar,DropdownMenu, DropdownItem,TreeSelect,List,Tab,Tabs,Sticky,Field,Dialog,DatetimePicker,Popup,Notify,Uploader,ImagePreview,Form,Picker,Switch,SwipeCell,IndexBar, IndexAnchor,Divider,Empty,Overlay,Skeleton,Checkbox,RadioGroup,Radio,Collapse, CollapseItem,Calendar} from 'vant';


Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Button);
Vue.use(Search);
Vue.use(PullRefresh);
Vue.use(VuePageStack, { router });
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Lazyload,{

  loading:lazyImage,

  error:lazyImage

});
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(NavBar);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(TreeSelect);
Vue.use(List);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Sticky);
Vue.use(Field);
Vue.use(Dialog);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(Notify);
Vue.use(Uploader);
Vue.use(ImagePreview);
Vue.use(Form);
Vue.use(Picker);
Vue.use(Switch);
Vue.use(SwipeCell);
Vue.use(IndexBar);
Vue.use(IndexAnchor);
Vue.use(Divider);
Vue.use(Empty);
Vue.use(Overlay);
Vue.use(Skeleton);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Calendar);


//复制用插件

Vue.use(VueClipboard);

Vue.config.productionTip = false;
Vue.prototype.$eventBus = eventBus;

//写入一个通用方法，即过300毫秒才执行

Vue.prototype.$fky_callback = (callback,time)=>{

  global.setTimeout(()=>{

    callback();

  },time || 300)

}

Vue.mixin({
  filters:{
    $imageUrl(value) {
        if(!!value && (value.indexOf('http:') != -1 || value.indexOf('https:') != -1)){
          return value;
        }else{
          return process.env.VUE_APP_IMG_URL+value
        }
    }
  },

  methods: {
    $has(can,required){
      const canArray = this.$store.state.userInfo.btnRule;
      if (!canArray) return false
      
      const matches = [].concat(can)
      return required
        ? matches.every(n => canArray.includes(n))
        : matches.some(n => canArray.includes(n))
    }, 
    $role(can,required){
      const canArray = this.$store.state.userInfo.role;
      if (!canArray) return false
      
      const matches = [].concat(can)
      return required
        ? matches.every(n => canArray.includes(n))
        : matches.some(n => canArray.includes(n))
    },
    $getImageUrl(value){
      if(!!value && (value.indexOf('http:') != -1 || value.indexOf('https:') != -1)){
        return value;
      }else{
        return process.env.VUE_APP_IMG_URL+value
      }
    },
    $listLastCheck(list,pageSize){
      return !list ||
      list?.length === 0 ||
      list?.length < pageSize;
    }
  },

})

const isVue =  new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");


export default isVue;