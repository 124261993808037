
/**
 * 
 * 渠道报备列表路由，所有渠道报备列表相关写在此处
 * 
 *  
 * 
 * 
 * 
 * 注：此模块路由全部为异步路由
 * 
 * 
 * 
 */

const PERMISSION = 'CHANNELRECORD';


const router = [

    //渠道报备列表

    {

        path:'/channel/record/list',

        name:'channel/record/list',

        component:() => import(/* webpackChunkName: "channel_record_list" */ '@/views/channel/record_list'),

        meta:{

            lv:2,

            module:true,

            permission:PERMISSION

        }

    }

];

export default router;