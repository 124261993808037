<template>
   <div class="fky-wrapper">
       <div class="fky-cover">
            <div class="fky-hd">
                <div class="page-title">
                    <span>我的报备</span>
                    <span class="show" @click="fkyDisabled">预览</span>
                </div>
            </div>
            <div class="fky-bd">
                <div class="tips">
                    <div class="tips-bg" @click="fkyDisabled">上传二维码让客户加您微信<van-icon name="arrow" /></div>
                </div>
                <div class="content">
                    <section class="fky-column wd">
                            <div class="wd-data-show">
                                <van-grid :border="false">
                                <van-grid-item>
                                    <div class="number">0<i class="van-hairline--right line"></i></div>
                                    <p class="type">VR看房</p>
                                </van-grid-item>    
                                <van-grid-item>
                                    <div class="number">0<i class="van-hairline--right line"></i></div>
                                    <p class="type">视频看房</p>
                                </van-grid-item>           
                                <van-grid-item>
                                    <div class="number">0<i class="van-hairline--right line"></i></div>
                                    <p class="type">房产资讯</p>
                                </van-grid-item>        
                                <van-grid-item>
                                    <div class="number">0</div>
                                    <p class="type">精选楼盘</p>
                                </van-grid-item>
                            </van-grid>
                            </div>
                            <div class="wd-toolbar">
                                <div class="wd-toolbar-cover">
                                    <div class="item" @click="fkyDisabled">
                                        <van-icon name="setting-o icon"/><span>微店设置</span>
                                        <i class="van-hairline--right line"></i>
                                    </div>
                                    <div class="item" @click="fkyDisabled"><van-icon name="system-share icon"/><span>分享微店</span></div>
                                </div>
                            </div>
                    </section> 
                    <section class="fky-column wd-record">
                            <span>今天共0位微信用户留下了0条访问记录</span>
                            <van-icon name="arrow" />
                    </section>  
                    <section class="fky-column fky-title">
                            <van-divider
                            :style="{ color: '#cccccc', borderColor: '#d3d3d3', padding: '0 16px',margin:'0' }"
                            >
                            更专业的房产获客工具
                            </van-divider>
                    </section>
                    <section class="fky-column management-list no-margin-t" v-if="role == 'DISTRIBUTOR'">
                            <van-grid :border="false">
                                <van-grid-item :to="{name:'subDistributorRecordList'}">
                                    <div class="icon van-icon-system-order"></div>
                                    <p class="type">公司订单</p>
                                </van-grid-item>    
                                <van-grid-item class="fky-disabled" @click="fkyDisabled">
                                    <div class="icon van-icon-system-client"></div>
                                    <p class="type">公司客户</p>
                                </van-grid-item>           
                                <van-grid-item :to="{name:'statistics'}">
                                    <div class="icon van-icon-system-statistics"></div>
                                    <p class="type">数据统计</p>
                                </van-grid-item>        
                                <van-grid-item :to="{name:'staffList'}">
                                    <div class="icon van-icon-system-staff"></div>
                                    <p class="type">员工管理</p>
                                </van-grid-item>
                            </van-grid>
                    </section>
                    <section class="fky-column link">
                            <van-grid :border="false" :column-num="2">
                                <van-grid-item @click="gotoLink({name:'rapidRecord'})">
                                    <div class="item">
                                        <div class="thumb-cover"></div>
                                        <p class="type">快速报备</p>
                                    </div>
                                </van-grid-item>    
                                <!-- <van-grid-item class="fky-disabled" @click="fkyDisabled" :class="{'fky-disabled':role == 'AUDITOR' || role == 'AUDITORADMIN'}" v-if="role != 'AUDITOR' && role != 'AUDITORADMIN'">
                                    <div class="item">
                                        <div class="thumb-cover"></div>
                                        <p class="type">客户动态</p>
                                    </div>
                                </van-grid-item>            -->
                                <van-grid-item :to="{name:'recordList'}">
                                    <div class="item">
                                        <div class="thumb-cover"></div>
                                        <p class="type">我的订单</p>
                                    </div>
                                </van-grid-item>        
                                <van-grid-item 
                                @click="gotoLink({name:'customerList'},true)" 
                                :class="{'fky-disabled':role == 'AUDITOR' || role == 'AUDITORADMIN' || role == 'CHANNEL' || role == 'CHANNELJINGLI'}" 
                                v-if="role != 'AUDITOR' && role != 'AUDITORADMIN' && role != 'CHANNEL' && role != 'CHANNELJINGLI'">
                                    <div class="item">
                                        <div class="thumb-cover"></div>
                                        <p class="type">客户管理</p>
                                    </div>
                                </van-grid-item>
                            </van-grid>
                    </section>
                </div>
            </div>
       </div>
   </div>
</template>

<script>
import disable from "@/mixins/disable";
import { Divider } from 'vant';
export default {

    mixins:[disable],

    components:{

        [Divider.name]:Divider

    },

    data(){

        return{

            num:0

        }

    },

    computed:{

        //角色

        role(){

            return this.$store.state.userInfo.role;

        },


    },


    methods:{

        gotoLink(route,auditorCheck){

            this.$route.query.index = 1;
            
            if(auditorCheck && (this.role == 'AUDITOR' || this.role == 'AUDITORADMIN'  || this.role == 'CHANNEL' || this.role == 'CHANNELJINGLI')){

                this.$toast('无使用权限');

                return;

            }

            if(route.name && route.name == 'rapidRecord'){

                this.$store.commit('resetRecordData');

            }
            
            this.$nextTick(()=>{

                this.$router.push(route);

            })

        }

    }

}
</script>

<style lang="less">
.fky-wrapper{

    background: #f6f6f6;

    height:100%;

    .fky-cover{

        height:100%;

    }

    .fky-hd{

        padding:10px 20px;

        background-image:linear-gradient(to right,#009ee2,hsl(194, 100%, 75%));

        position: fixed;

        top:0;

        left:0;

        width: 100%;

        z-index:15;

        .page-title{

            font-size:18px;

            color:#fff;

            display: flex;

            justify-content: space-between;

            span:nth-child(2){

                font-size:16px;

            }

            .show:active{

                color:#eeeeee;

            }

        }

        .des{

            color:#fff;

            font-size:13px;

            padding-bottom:5px;

        }

    }

    .fky-bd{

        max-height:100%;

        overflow: hidden;

        overflow-y:auto;
        
        .content{

            padding:0 20px 50px;

        }

        .tips{

            padding:50px 0 80px;

            text-align: center;

            margin-top:0;

            background-image:linear-gradient(to right,#009ee2,hsl(194, 100%, 75%));

            .tips-bg{

                background: rgba(255,255,255,.2);

                display: inline-block;

                padding: 3px 15px 4px;

                font-size:13px;

                color:#ffffff;

                border-radius: 33px;

                i{

                    vertical-align: middle;

                    position: relative;

                    top:-1px;

                }

            }

        }

        .fky-column{

            border-radius: 4px;

            overflow: hidden;
            
            margin-top:15px;


            &.management-list{

                height:80px;

                display: flex;

                align-items: center;

                background: #fff;


                .van-grid{

                    width: 100%;

                    .van-grid-item{

                        &:first-child{

                            .icon{

                                background-image: linear-gradient(top, #b57bda, #f3a2e0); 
                                -webkit-background-clip: text; 
                                -webkit-text-fill-color: transparent; 

                            }

                        }

                        &:nth-child(2){

                            .icon{

                                background-image: linear-gradient(top,#f89439, #fbbf5a); 
                                -webkit-background-clip: text; 
                                -webkit-text-fill-color: transparent; 

                            }

                        }  
                        
                        &:nth-child(3){

                            .icon{

                                background-image: linear-gradient(top,#ff775d, #edb08c); 
                                -webkit-background-clip: text; 
                                -webkit-text-fill-color: transparent; 

                            }

                        }

                        &:nth-child(4){

                            .icon{

                                background-image: linear-gradient(top,#4da2e0, #68d5e9); 
                                -webkit-background-clip: text; 
                                -webkit-text-fill-color: transparent; 

                            }

                        }

                        &.fky-disabled{

                            .icon{

                                background-image: linear-gradient(top,#ddd, #ddd); 
                                -webkit-background-clip: text; 
                                -webkit-text-fill-color: transparent; 

                            }

                        }

                    }

                }

                .icon{

                    font-size:30px;

                    font-weight: bold;

                }

                .type{

                    font-size:13px;
                    
                    padding-top:6px;

                    color:#666666;

                }

            }

            &.link{

                border-radius: 0;

                .van-grid{

                    .van-grid-item__content{

                        border-radius: 5px;

                        overflow: hidden;

                    }


                    .van-grid-item{

                        height:75px;

                        padding-bottom:0;

                        margin-bottom:15px;
    

                        &:nth-child(odd){

                            padding-right:15px;

                        }

                        &:last-child,&:nth-last-child(2){

                            padding-bottom:0;

                        }

                        

                        .item{

                            width: 100%;

                            height:100%;

                            display: flex;

                            justify-content: center;

                            align-items: center;

                            .thumb-cover{

                                width: 42px;

                                height:42px;

                                border-radius:50%;

                                margin-right:12px;

                                background-repeat: no-repeat;

                                background-position: center center;

                                background-size:100% 100%;

                            }

                            .type{

                                font-size:15px;

                                color:#666666;

                            }

                        }

                        &:first-child .item .thumb-cover{

                            background-image:url('../../assets/images/record.png');

                        }  
                        
                        &:nth-child(2) .item .thumb-cover{

                            background-image:url('../../assets/images/dt.png');

                        }  
                        
                        &:nth-child(3) .item .thumb-cover{

                            background-image:url('../../assets/images/order.png');

                        }  
                        
                        &:nth-child(4) .item .thumb-cover{

                            background-image:url('../../assets/images/gl.png');

                        }

                        &:nth-child(odd){

                            padding-right:2vw;

                        } 
                        
                        &:nth-child(even){

                            padding-left:2vw;

                        }

                        &:nth-child(3),&:nth-child(4){

                            height:75px;

                        }

                        
                        &.fky-disabled{
                            
                            opacity: .9;
                            
                            .thumb-cover{

                               -webkit-filter: grayscale(80%); /* Chrome, Safari, Opera */
                                filter: grayscale(80%);
                            }

                        }

                    }

                }

            }

            &.wd{

                background: #fff;

                padding:0px 0 20px;

                margin-top:-70px;

                .wd-data-show{

                    .number{

                        font-size:38px;

                        color:#666666;

                    }

                    .type{

                        color:#666666;

                        font-size:13px;

                    }

                    .van-grid-item__content{

                        position: relative;
                        
                        .line{

                            position: absolute;

                            width: 2px;

                            height:25%;

                            right:0;

                            top:50%;

                            transform: translateY(-50%);

                            &:after{

                                border-color:#dddddd;

                            }

                        }

                    }

                }

                .wd-toolbar{

                    padding: 5px 15px 0;


                    .wd-toolbar-cover{

                        background: #f8f8f8;

                        display: flex;
                        
                        border-radius:4px;

                        .item{

                            flex:1;

                            text-align: center;

                            height:36px;

                            line-height:38px;

                            font-size:12px;

                            position: relative;

                            .line{

                                position: absolute;

                                width: 2px;

                                height:40%;

                                right:0;

                                top:50%;

                                transform: translateY(-50%);

                                &:after{

                                    border-color:#dddddd;

                                }

                            }
                            

                            &:first-child .icon{

                                position: relative;

                                top:-1px;

                                padding-right:8px;

                            }

                            .icon{

                                font-size:17px;

                                vertical-align: middle;

                                padding-right:7px;

                            }

                            span{

                                vertical-align:middle

                            }

                            &:active{

                                color:#999999;

                            }

                        }

                    }

                }

            }

            &.wd-record{

                background: #fff;

                display: flex;

                justify-content: space-between;

                align-items: center;

                padding: 10px 15px 10px 20px;

                font-size:13px;

                color:#d6b15a;

            }
            
            &.fky-title{

                text-align: center;
                
                margin-top:0;

                padding:15px 0 10px;

            }

            &.no-margin-t{

                margin-top:0

            }
        }

    }

}
</style >
