<template>
   <div class="wrapper">
    <div class="tabs">
         <keep-alive>
            <template v-for="(item,index) in homePages">
                <component :is="item.component" :key="index" v-if="active==index" :ref="item.ref" class="tab-item"></component>
            </template>
        </keep-alive>
    </div>
    <van-tabbar v-model="active" active-color="#00a0e4" inactive-color="#ccc">
        <van-tabbar-item v-for="(item,index) in homePages" :key="index" :icon="item.icon">
            {{item.name}}
        </van-tabbar-item>
    </van-tabbar>
   </div>
</template>

<script>
import indexPage from "@/views/common/index";
import fkyPage from "@/views/common/fky";
import myPage from "@/views/common/my";
import sessionStorage from "@/utils/sessionStorage";
export default {
    
    name:'home',

    components:{

        indexPage,

        fkyPage,

        myPage

    },

    data(){

        return{

            active:null,

            oldActive:0,

            homePages:[

                {
                    name:'首页',

                    icon:"system-home",

                    component:'indexPage',

                    ref:'index_page'

                },{
                    name:'我的',

                    icon:"system-my",

                    component:'myPage',

                    ref:'my-page'

                },

            ],

            pageLock:false,

            cancelLock:false

        }

    },

    methods:{

        //提示绑定微信弹窗,一次页面打开只提示一次

        bindWxTips(){

            //首先检查此次是否弹出了此窗口

            let check = sessionStorage.StorageGetter("bindWxTipsAlert");

            if(check) return;

            //首先检查客户是否绑定了微信

            if(!this.$store.state.userInfo.bindWx && this.$store.state.userInfo.ready){

                sessionStorage.StorageSetter("bindWxTipsAlert",true);

                this.$dialog.confirm({

                    title:'未绑定微信',

                    message:'我们发现您还未绑定微信，请绑定微信，以便能够接收到报备通知。',

                    confirmButtonText:"前往绑定",

                    confirmButtonColor:'#00a0e4'

                }).then(()=>{

                    this.active = 2;

                }).catch(()=>{})

            }

        },

    },

    activated() {
        // if(this.$store.state.userInfo.isDefaultPassword){
        //     let inv_id = setTimeout(()=>{
        //         clearTimeout(inv_id);
        //         this.$dialog.alert({
        //             allowHtml:true,
        //             title:'请修改密码',
        //             message:'<span style="color:rgb(0, 160, 228)">系统已禁用！</span>我们发现您还在使用初始或是过于简单的密码，这将会对您的数据安全造成重大隐患，请立即进行密码修改，以保证数据安全！<span style="color:rgb(0, 160, 228)">修改密码后即可正常使用。</span>',
        //             confirmButtonColor:'#00a0e4',
        //             confirmButtonText:'前往修改'
        //         }).then(()=>{
        //             this.$nextTick(()=>{
        //                 this.$router.push({name:'changePassword'});
        //             })
        //         })
        //     },300)
        // }


        if(this.pageLock) return;

        this.pageLock=true;
        const home_type = this.$route.query.active || this.$route.params.active;

        this.active = home_type == 'index' ? 0 : home_type == 'my' ? 1 : 0;

    },


}
</script>

<style scoped lang="less">
.wrapper{

    position: relative;

    .tabs{

        position: absolute;

        top:0;

        bottom:50px;

        left:0;

        right:0;

        .tab-item{

            width: 100%;

            height:100%;

        }

    }

}
</style>
