
/**
 * 
 * 楼盘拓展路由，所有拓展相关写在此处
 * 
 *  
 * 
 * 
 * 
 * 注：此模块路由全部为异步路由
 * 
 * 
 * 
 */

const PERMISSION = 'EXTENSIONSTATISTICS';


const router = [

    {

        path:'/estate_development/statistics/index',

        name:'estate_development_statistics_index',

        component:() => import(/* webpackChunkName: "estate_development_index" */ '@/views/estate_development/statistics_index'),

        meta:{

            lv:2,

            module:true,

            permission:PERMISSION

        }

    },
    {
        path:"/estate_development/statistics/detail/:type",

        name:"estate_development_statistics_detail",

        component:() => import(/* webpackChunkName: "estate_development_detail" */ '@/views/estate_development/statistics_detail'),

        meta:{

            lv:4,

            module:true,

            permission:PERMISSION

        }


    },
    {

        path:'/estate_development/statistics/house/detail/:id',

        name:"estate_development_statistics_house_detail",

        component:() => import(/* webpackChunkName: "estate_development_detail" */ '@/views/estate_development/statistics_house_detail'),

        meta:{

            lv:5,

            module:true,

            permission:PERMISSION

        }

    }

];

export default router;