import Vue from "vue";
import Vuex from "vuex";
import routerStore from "@/store/modules/router";
import userInfo from "@/store/modules/userInfo";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {

    routerStore,
    userInfo
  }
});
