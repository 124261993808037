import channelRoute from "@/router/modules/channel";
import estate_development from "@/router/modules/estateDevelopment";
import houseManage from "@/router/modules/houseManage";
import subdistributorManage from "@/router/modules/subdistributorManage";
import channelRecordList from '@/router/modules/channelRecordList'
import estateDevelopmentRecordList from '@/router/modules/estateDevelopmentRecordList';
import recordDetail from "@/router/modules/recordDetail";
import recordAuditList from "@/router/modules/recordAuditList"
/**
 * 
 * 路由表，根据权限配置添加
 * 
 * 1.通用路由表，表示可能会被几个模块调用的公共路由表，此类路由不生成菜单导航，
 * 
 *   比如，报备单详情，和报备单审核详情， 可能会被渠道报备列表和拓展报备列表同时调取，
 * 
 *   所以需要在需要调取此模块的路由表下添加,commonRoute,格式为一个数组，可引入多个公共模块
 * 
 */


 const routeList={

    //渠道统计

    CHANNELSTATISTICS:{

        icon:'system-statistics',

        menuPath:'/channel/statistics/index',

        route:channelRoute,

        text:'渠道统计'

    },

    //拓展统计
    
    EXTENSIONSTATISTICS:{

        icon:'system-statistics_qd',

        menuPath:'/estate_development/statistics/index',

        route:estate_development,

        text:'拓展统计'

    },

    // //楼盘管理

    // HOUSEMANAGE:{

    //     icon:"system-house",

    //     menuPath:'/house/manage/list',

    //     route:houseManage,

    //     text:'楼盘管理'

    // },

    // //分销商管理

    // SUBSUBDISTRIBUTORMANAGE:{

    //     icon:"system-RectangleCopy",
        
    //     menuPath:"/subdistributor/manage/index",

    //     route:subdistributorManage,

    //     text:'分销商管理'

    // },

    //渠道报备列表

    CHANNELRECORD:{

        icon:"system-channel-record-list",
        
        menuPath:"/channel/record/list",

        route:channelRecordList,

        text:'渠道报备列表',

        commonRoute:['RECORDCOMMON']

    },

    //拓展报备列表

    EXTENSIONRECORD:{

        icon:"system-expand-record-list",
        
        menuPath:"/estate/development/record/list",

        route:estateDevelopmentRecordList,

        text:'拓展报备列表',

        commonRoute:['RECORDCOMMON']

    },

    //报备专员管理

    RECORDAUDITLIST:{

        icon:'system-staff',

        menuPath:"/record/audit-list",

        route:recordAuditList,

        text:'报备审核列表',

        commonRoute:['RECORDCOMMON']

    },

    //报备通用路由表
    
    RECORDCOMMON:{

        route:recordDetail,

        text:'报备通用路由表'

    },

    
 }


 


 export default routeList;