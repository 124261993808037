<template>
   <div class="error-pagewrapper">
       <van-empty image="error" description="页面走丢了,请点击此处返回首页" @click.native="gotoIndex" v-if="$store.state.userInfo.ready && this.$route.path !='/' && this.show" />
   </div>
</template>

<script>
export default {

    data(){

        return{

            show:false

        }

    },

    methods:{

        gotoIndex(){

            this.$router.replace({name:"home"});

        }

    },

    mounted(){

        global.setTimeout(()=>{

            this.show = this.$store.state.userInfo.show;

        },2000)

    }

}
</script>

<style scoped lang="less">
.error-pagewrapper{

    height:100%;

    padding-top:10vh;

    
    .fade-enter-active, .fade-leave-active {
            transition: opacity .5s;
            opacity: .5;
    }
        .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
            opacity: 0;
    }


}
</style>
