
import home from "@/views/common/home";
import userInfo from "@/views/common/userInfo";
import userInfoSet from "@/views/common/userInfoSet";
import errPage from "@/views/common/404";
import changePassword from "@/views/common/changePassword";
import retrievePassword from '@/views/common/retrievePassword'
import login from "@/views/common/login";
import help from "@/views/common/help";
import aboutUs from "@/views/common/aboutUs";
import userAgreement from "@/views/common/userAgreement";



/**
 * 
 * meta 里添加的参数说明
 * 
 * lv 页面层级
 * 
 * live 是否可以在未登录状态下展示
 * 
 * common 是否为公共页面，即不经过路由验证的页面
 * 
 */

const router = [

    { 
        path: '*',

        name:'404',

        component:errPage,

        meta:{

            lv:0

        }

    },


    {

        //首页

        path:'/home',

        name:'home',

        component:home,

        meta:{

            lv:1,

            common:true

        }


    },{

        //用户信息

        path:'/user/info',

        name:'userInfo',

        component:userInfo,

        meta:{

            lv:2,

            common:true

        }


    },{

        //个人信息修改

        path:'/user/info/set/:type',

        name:'userInfoSet',

        component:userInfoSet,

        meta:{

            lv:3,

            common:true

        }


    },{

        //修改密码

        path:'/change/password',

        name:'changePassword',

        component:changePassword,

        meta:{

            lv:4,

            common:true

        }


    },{

        //找回密码

        path:'/retrieve/password',

        name:'retrievePassword',

        component:retrievePassword,

        meta:{

            lv:5,

            live:true,

            common:true

        }


    },{

        //登录

        path:"/login",

        name:'login',

        component:login,

        common:true,

        meta:{

            lv:2

        }

    },{

        //帮助

        path:'/help',

        name:"help",

        component:help,

        meta:{

            lv:3,

            common:true

        }

    },{

        //关于房小云

        path:'/aboutUs',

        name:"aboutUs",

        component:aboutUs,

        meta:{

            lv:3,

            live:true,

            common:true

        }

    },{

        //用户协议

        path:'/user/agreement',

        name:"userAgreement",

        component:userAgreement,

        meta:{

            lv:4,

            live:true,

            common:true

        }

    }

]




export default router;