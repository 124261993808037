import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import eventBus from "@/eventBus"

//modules

import common from '@/router/modules/common';



//阻止弹出报错提示

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location) {

  return originalPush.call(this, location).catch(err => err)
  
}


Vue.use(VueRouter);

const routes = [

  ...common,

  // ...channel,

  // ...estate_development

];





const createRouter = ()=> new VueRouter({

  mode: "history",

  // base: process.env.VUE_APP_SYSTEM_ROUTER_BASE,
  
  routes

});

const router = createRouter();

//路由验证

router.beforeEach((to, from, next) => {
  if(!store.state.userInfo.show){

    next();

  }else{
    //当初始化完毕以后，访问登录页面，阻止访问

    if(to.name == 'login' && store.state.userInfo.ready){

      if(from.name !='login'){

        next({...from,replace:true});
        
      }else{

        next({name:'home',replace:true});

      }

    }else if(!store.state.userInfo.ready && to.name !='login' && !to.meta.live){

      //初始化未完成,只有meta里设置了live的页面能够展示

      next({name:'login',replace:true});

    }else if(to.path == '/' || !to.path){

      //准备完毕

      if(store.state.userInfo.ready){

        if(from.name !='login'){

          //当准备完毕

          next({...from,replace:true});

        }else{

          //当准备完毕

          next({name:'home',replace:true});

        }

      }else{

        next({name:"login",replace:true});

      }
    

    }else{

      next();

    }

  

  }



})




//路由跳转完毕

router.afterEach((to) => {

    //判断是否存在权限判断

  if(to.meta.permission){

    store.commit('setRouterData',{key:'pagePermission',value:to.meta.permission});
  
  }else{
  
    //不存在就清除基础权限
  
    store.commit('setRouterData',{key:'pagePermission',value:null});
  
  }

  if(!store.state.routerStore.isFirstPush){

    //监听是否为第一次加载

    store.commit('setRouterData',{

      key:"isFirstPush",

      value:true

    })

    if(!store.state.userInfo.show){

      //触发初始化事件

      eventBus.$emit('appInit')

    }

  }



})


export const resetRouter =()=>{

  const newRouter = createRouter();

  router.matcher = newRouter.matcher;

}

router.fkyResetRouter = ()=>{

  const newRouter = createRouter();

  router.matcher = newRouter.matcher;

}


export default router;
