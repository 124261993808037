<template>
  <div id="app">
    <layout></layout>
  </div>
</template>

<script>
import layout from "@/views/layout/layout"
export default {
  
  components:{

    layout

  }

}
</script>
<style lang="less">
  #app{

    height:100%;

    width: 100%;

  }
</style>
