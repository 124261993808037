<template>
   <div class="change-passwrod-wrapper">
       <div class="hd"><van-nav-bar left-arrow safe-area-inset-top title="修改密码" @click-left="back"></van-nav-bar></div>
       <div class="bd">
           <van-form @submit="onSubmit" label-width="5em" validate-first>
            <van-field
                v-model="oldPassword"
                label="原密码"
                right-icon="eye"
                name="oldPssword"
                :type="oldShow ? '' :'password'"
                placeholder="原密码"
                :rules="[{ required: true, message: '请填写原密码' }]"
                :class="{show:oldShow}"
                @click-right-icon="oldShow=!oldShow"
            />
            <van-field
                v-model="password"
                :type="newShow ? '' : 'password'"
                name="password"
                label="新密码"
                right-icon="eye"
                placeholder="新密码，最少六位"
                :class="{show:newShow}"
                @click-right-icon="newShow=!newShow"
                :rules="[{ required: true, message: '请填写新密码' }]"
                ref="password"
            />  
            <van-field
                v-model="passwordMore"
                :type="confirmShow ? '' : 'password'"
                name="passwordMore"
                label="确认密码"
                right-icon="eye"
                placeholder="确认密码"
                :class="{show:confirmShow}"
                @click-right-icon="confirmShow=!confirmShow"
                :rules="[{  required: true, message: '请再一次输入新密码' }]"
                ref="passwordmore"
            />
            <div class="align-right"><router-link :to="{name:'retrievePassword'}" class="retrieve-Password">忘记原密码?</router-link></div>
            <van-button round block type="info" native-type="submit" :disabled="!btnDisabled">提交</van-button>
            </van-form>
       </div>
   </div>
</template>

<script>
import api from '@/api'
import backMixin from "@/mixins/back";
import {isEqual} from "@/utils";
export default {

    mixins:[backMixin],

    data(){

        return{

            oldPassword:'',

            password:'',

            passwordMore:'',

            oldShow:false,

            newShow:false,

            confirmShow:false

        }

    },

    computed:{

        btnDisabled(){

            return this.oldPassword && this.password && this.passwordMore;

        }

    },



    methods:{

        onSubmit(){

             //判断是否满足六位

            if(isEqual(this.password,this.oldPassword)){

                this.$notify({type:"danger",message:"新旧密码不能相同"});

                this.$refs.password.focus();

                return;

            }

            //判断是否满足六位

            if(this.password.length <6){

                this.$notify({type:"danger",message:"新密码最少六位"});

                this.$refs.password.focus();

                return;

            }

            //判断两次密码是否输入一致

            if(!isEqual(this.password,this.passwordMore)){

                this.$notify({type:"danger",message:"两次密码输入不一致"});

                this.$refs.passwordmore.focus();

                return false;

            }

            
            this.$toast.loading({

                message:'提交中...',

                duration:0

            })

            api.changePassword({

                options:{

                    oldPassword:this.oldPassword,

                    password:this.password,

                    passwordMore:this.passwordMore

                },

                success:(res)=>{

                    if(res.type == 200){
                        //清除掉密码修改锁定
                        this.$store.commit('setUserData',{
                            key:'isDefaultPassword',
                            value:false,
                            save:true
                        })
                        this.$dialog.alert({

                            title:'密码修改成功',

                            message:'密码修改成功，请重新登陆',

                            confirmButtonColor:"#00a0e4"

                        }).then(()=>{

                            this.$toast.loading({

                                    message:'正在登出...',

                                    duration:0,

                                    forbidClick:true

                                })

                            api.logOut({

                            success:(res)=>{

                                if(res.type == 200){

                                    this.$store.commit('resetUserData');

                                    this.$nextTick(()=>{

                                        this.$router.replace({name:"login"});

                                    })

                                }else{

                                    this.$dialog.alert({

                                        title:'退出失败',

                                        message:res.msg || "退出失败，请稍后重试"

                                    }).then(()=>{})

                                }

                            },

                            error:(err)=>{

                                console.log(err);

                                this.$dialog.alert({

                                    title:'退出失败',

                                    message:"退出失败，请求异常，请稍后重试"

                                }).then(()=>{})

                            },

                            complete: ()=> {

                                this.$toast.clear();
                                
                            }

                    })


                            })


                    }else{

                        this.$dialog.alert({

                            title:'修改失败',

                            message:res.msg || '密码修改失败，请稍后重试'

                        }).then(()=>{})

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'修改失败',

                        message:'密码修改失败，提交异常，请稍后重试'

                    }).then(()=>{})


                },

                complete:()=>{

                    this.$toast.clear();

                }

            })


        },



    }

}
</script>

<style lang="less">
.change-passwrod-wrapper{

    background:#f6f6f6;

    height:100%;

    .van-button{

        margin:20px auto 0;

        width: 80%;

    }

}
</style>
