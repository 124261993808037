import api from "@/api";

import dataStorage from "@/utils/dataStorage";

import sessionStorage from '@/utils/sessionStorage';

import {isArray,initCityList,isString} from "@/utils";

import {Decrypt,Encrypt} from "@/utils/crypto";

import routeList from "@/config/routeModuleConfig";
import eventBus from "@/eventBus";
import route from "@/router";
import { isObject } from "lodash";

const state = {

    ready:false,

    show:false,

    name:'',

    phone:'',

    sex:0,

    resume:'',

    subdistributorName:'',

    id:null,

    avatar:'',

    //DISTRIBUTOR 表示业务员中的管理人员
    //MANAGER 经理人 
    //STAFF 经纪人
    //AUDITOR 报备专员
    //CHANNEL 渠道专员
    //AUDITORADMIN 报备专员管理员

    role:[],

    //此分销公司顶级管理员

    isDistributorAdmin:false,

    //门店名称

    shopName:'',

    //以下的数据全都是用来选择的数据,地区

    cityList:null,

    //检查用城市列表

    checkCityList:[],

    //list价格区间

    priceList:null,

    //楼盘特色

    houseTeseList:null,

    //经纪人列表

    staffList:null,

    //状态列表

    recordStatusList:null,

    //状态名称对象

    recordStatusTextObj:{},

    //当前城市

    currentCity:null,

    //客服电话

    kefPhone:null,

    //是否绑定微信

    bindWx:0,

    token:null,

    //是否已经提示过需要绑定微信

    bindWxTips:false,

    //权限列表

    h5Rule:[],

    //导航列表
    
    menuList:[],

    //权限列表 

    permissionList:[],

    //是否还在使用初始密码
    
    isDefaultPassword:false,

    defaultMenus:{
        "HOUSEMANAGE": {
            "id": 10,
            "pid": 9,
            "title": "内部楼盘管理",
            "accesscode": "HOUSEMANAGE",
            "path": "/house/manage/list",
            "href": "baobei/house/getHouseList.html",
            "icon": "",
            "menu_status": 1,
            "sort": 1
        },
        "CHANNELRECORD": {
            "id": 41,
            "pid": 9,
            "title": "渠道报备单",
            "accesscode": "CHANNELRECORD",
            "path": "/channel/record/list",
            "href": "CHANNELRECORD",
            "icon": "",
            "menu_status": 1,
            "sort": 4
        },
        "EXTENSIONRECORD": {
            "id": 42,
            "pid": 9,
            "title": "拓展报备单",
            "accesscode": "EXTENSIONRECORD",
            "path": "/estate/development/record/list",
            "href": "EXTENSIONRECORD",
            "icon": "",
            "menu_status": 1,
            "sort": 5
        },
        "SUBSUBDISTRIBUTORMANAGE": {
            "id": 13,
            "pid": 9,
            "title": "分销商管理",
            "accesscode": "SUBSUBDISTRIBUTORMANAGE",
            "path": "/subdistributor/manage/index",
            "href": "baobei/distributor/getSubdistributorTableData.html",
            "icon": "fky-system-subdistributor",
            "menu_status": 1,
            "sort": 6
        },
        "RECORDAUDITLIST": {
            "id": 53,
            "pid": 57,
            "title": "报备审核列表",
            "accesscode": "RECORDAUDITLIST",
            "path": "/record/audit-list",
            "href": "RECORDAUDITLIST",
            "icon": "",
            "menu_status": 1,
            "sort": 40
        },
        "BAOBEIUSERDAILISTATISTICS": {
            "id": 56,
            "pid": 57,
            "title": "代理报备数据",
            "accesscode": "BAOBEIUSERDAILISTATISTICS",
            "path": "/usermanage/baobeiuserdailistatistics/index",
            "href": "BAOBEIUSERDAILISTATISTICS",
            "icon": "",
            "menu_status": 1,
            "sort": 41
        },
        "CHANNELSTATISTICS": {
            "id": 39,
            "pid": 38,
            "title": "渠道统计",
            "accesscode": "CHANNELSTATISTICS",
            "path": "/channel/statistics/index",
            "href": "CHANNELSTATISTICS",
            "icon": "system-statistics",
            "menu_status": 1,
            "sort": 50
        },
        "EXTENSIONSTATISTICS": {
            "id": 40,
            "pid": 38,
            "title": "拓展统计",
            "accesscode": "EXTENSIONSTATISTICS",
            "path": "/estate_development/statistics/index",
            "href": "EXTENSIONSTATISTICS",
            "icon": "system-statistics_qd",
            "menu_status": 1,
            "sort": 50
        }
    },

    permissionListDefault:[
        "HOUSEMANAGE",
        "HOUSEOPERATIONMANAGE",
        "CHANNELRECORD",
        "EXTENSIONRECORD",
        "SUBSUBDISTRIBUTORMANAGE",
        "RECORDEDIT",
        "RECORDAUDITLIST",
        "BAOBEIUSERDAILISTATISTICS",
        "ALLRECORDMANAGE",
        "ALLSUBDISTRIBUTORMANAGE",
        "CHANNELSTATISTICS",
        "EXTENSIONSTATISTICS",
        "ALLHOUSEMANAGE",
        "HOUSEAREARULL",
        "BAOBEISTATISTICSALLAREARULL",
        "RECORDAUDITLISTRULL"
    ],
    companyId:'',
    departmentName:'',
    userCompany:{},
    btnRule:[],
    //休假状态
    vacation:0
}

const mutations = {

    //设置数据

    setUserData(state,{key,value,save}){
        
        state[key] = value;

        if(save){

            let options = {
                cityList:state.cityList,
                houseTeseList:state.houseTeseList,
                priceList:state.priceList,
                moreList:state.moreList,
                kfPhone:state.kefPhone,
                recordStatusTextObj:state.recordStatusTextObj,
                h5Rule:state.h5Rule,
                userCompany:state.userCompany,
                btnRule:state.btnRule,
                userInfo:{
                    "id":state.id,
                    "name":state.name,
                    "sex":state.sex,
                    "phone":state.phone,
                    "resume":state.resume,
                    "avatar":state.avatar,
                    'role':state.role,
                    "bindWx":state.bindWx,
                    'isDefaultPassword':state.isDefaultPassword,
                    'userId':state.userId,
                    'companyId':state.companyId,
                    'departmentName':state.departmentName,
                    'resident':state.resident,
                    'sales':state.sales,
                    'report':state.report,
                    'expand':state.expand,
                    'manage':state.manage,
                    'vacation':state.vacation
                },
                token:state.token
            }

            dataStorage.StorageSetter('user_common_data',{token:state.token,companyId:state.companyId});

            sessionStorage.StorageSetter('user_common_data',options);

        }

    },

    //数据初始化

    initData(state,data,update){
        //权限列表
        let roleList = ['report','resident','sales','expand','manage']
        try{
            /**
         * 
         * 判断data 是否为字符串
         * 
         * 是的话解密一下
         * 
        */

       if(isString(data)){

        data = Decrypt(data);

        }

        if(data?.cityList && data.cityList[0]?.id){

            data.cityList.unshift({

                id:0,

                text:'不限',

                children:[]

            })

        }


        if(!update){

            //设置城市列表

            state.cityList = data?.cityList;
            state.houseTeseList = data?.houseTeseList;
            state.priceList = data?.priceList;
            state.recordStatusList = data?.recordStatusList;
            state.permissionList = data?.h5Rule || [];
            state.userCompany = data?.userCompany || [];
            state.btnRule = data?.btnRule || [];
            // 序列化状态列表，以便直接提取对应的名称

            if(data.recordStatusList && isArray(data.recordStatusList)){

                data.recordStatusList.forEach(el=>{

                    state.recordStatusTextObj[el.value] = el.text;

                })

            }

            //设置选择城市

            if(isArray(state.cityList) && state.cityList.length>1){
                //处理一下城市列表,处理成一列数组
                state.checkCityList = initCityList(state.cityList);
            }

            if(data?.userInfo){
                //设置用户数据
                state.name = data.userInfo?.name;
                state.phone = data.userInfo?.phone;
                state.sex = data.userInfo?.sex;
                state.resume = data.userInfo?.resume;
                state.id = data.userInfo?.id;
                state.avatar = data.userInfo?.avatar;
                state.role=data.userInfo?.role;
                state.bindWx = data.userInfo?.bindWx;
                state.companyId = data.userInfo?.companyId;
                state.departmentName = data?.userInfo?.departmentName;
                state.companyName = data?.userInfo?.companyName;
                state.vacation = data?.userInfo?.vacation;
                //设置token
                state.token = data?.token;
                //设置权限
                state.h5Rule = data?.h5Rule;
                //设置密码情况
                state.isDefaultPassword = !!data?.userInfo?.isDefaultPassword;
                //缓存权限
                state.resident = data?.userInfo.resident;
                state.sales = data?.userInfo.sales;
                state.report = data?.userInfo.report;
                state.expand = data?.userInfo.expand;
                state.manage = data?.userInfo.manage;
                //保存数据
                dataStorage.StorageSetter('user_common_data',{token:data.token,companyId:data.userInfo?.companyId});
                state.role = roleList.filter(el=>{
                    return isObject(data?.userInfo) && data?.userInfo[el]; 
                });
                state.ready = true;
            }

        }else{

            state.role=data.userInfo.role;

        }
        //提取存在权限的路由路径
        
        this.commit('setRouter',data.menus);

        //储存临时数据

        sessionStorage.StorageSetter('user_common_data',data);
        }catch(err){
            console.log(err)
        }

    },

    //重置数据

    resetUserData(state){

        //准备状态取消

        state.ready = false;

        state.token = null;

        state.bindWxTips = false;

        state.isDefaultPassword = false;

        dataStorage.StorageRemove('user_common_data');

        sessionStorage.StorageRemove("user_common_data");

    },

    //设置路由路径，以及生成菜单列表

    setRouter(state,_menuList={}){
        //菜单列表
        let menuList = [];
        //路由列表
        let _routeList = [];
        //通用列表,用来检查是否注册过公共模块
        let commonRouteList = {};
        //提取出有权限的列表
        state.permissionList.forEach(el=>{
            if(routeList[el]){

                routeList[el].text = _menuList[el]?.title || routeList[el].text;

                menuList.push(routeList[el]);

                _routeList.push(...routeList[el].route);

                //检查是否存在公共模块调用

                if(isArray(routeList[el]?.commonRoute)){

                    let list = routeList[el].commonRoute;

                    //遍历一下公共模块

                    list.forEach(_el=>{

                        //检查是否已经注册过,没有注册过才执行

                        if(!commonRouteList[_el]){

                            commonRouteList[_el] = true;

                            _routeList.push(...routeList[_el].route);

                        }

                    })

                }

            }

        });

        //重置路由表

        route.fkyResetRouter();
        //添加对应的权限列表

        _routeList.forEach(el=>{

            route.addRoute(el);

        });

        //生成导航列表

        state.menuList = menuList.map(el=>{

            return{icon:el.icon,path:el.menuPath,text:el.text};

        });

        //判断是否需要刷新

        if(!route.currentRoute.meta.common){

            //读取是否存在query参数

            const query = route.currentRoute.query;

            let _query = {};

            //遍历对象，去除对应的参数

            if(query){

                Object.keys(query).map(el=>{

                    if(el != 'stack-key'){

                        _query[el] = query[el];

                    }

                })

            }

            //遍历对象

            route.replace({path:route.currentRoute.path,query:_query})

        }

    }


}

const actions = {

    /**
     *   获取用户数据 , 数据获取成功后，即表示用户登录成功后，将数据储存在session storage 中
     *   刷新时检查此数据是否存在 如果存在就直接刷新，不存在就进行登录
     *   储存格式应当为 user-state 
     * 
     *   将常用的数据储存起来
     * 
     */

    getInitData({commit},{callback,path,isLive,nowPath}){

        //首先映带判断是否存在原始数据

        const userCommonData = dataStorage.StorageGetter('user_common_data');

        let sessionCommonData;


        try{

            sessionCommonData = sessionStorage.StorageGetter('user_common_data');

        }catch(err){

            //报错说明为纯字符串

            sessionCommonData = sessionStorage.StorageGetter('user_common_data',true);

        }

        //设置token去进行数据请求,当存在临时数据的时候优先使用

        if(sessionCommonData){
            commit('initData',sessionCommonData);

            callback && callback({path:path},'success');

        }else{

            if(userCommonData){

                commit('setUserData',{
    
                    key:'token',
    
                    value:userCommonData.token
    
                });
                
                commit('setUserData',{
    
                    key:'companyId',
    
                    value:userCommonData.companyId
    
                });
    
                api.getInitConfig({
    
                    success:(res)=>{
        
                        if(res.type == 200){

                            //触发全局广播方法
                            let invId = setTimeout(()=>{
                                clearTimeout(invId);
                                eventBus.$emit('toast',{
                                    message:`当前登录公司为：${res.data.userCompany?.companyName}`,
                                    position:'bottom',
                                    duration:3000
                                })
                            },300)

                            commit('initData',res.data);
        
                            if(path && path !='/'){
        
                                callback && callback({path:path},'success');
        
                            }else{
        
                                callback && callback({name:"home"},'success');
        
                            }

                    
                        }else{
        
                            callback && callback({name:'login'},'timeout');
        
                        }
        
                    },
        
                    error:(err)=>{
        
                        console.log(err);
        
                        callback && callback({name:'login'},'error');
        
                    }
        
                })
        
    
            }else{

      
    
                //不存在缓存数据的时候且不会公共展示页面的情况下，进行登录
                if(isLive || (!nowPath || nowPath == '/')){
                        
                    callback && callback({name:"home"},'success');

                  

                }else{

                    callback && callback({name:'login'},'error');

                }
    

    
            }


        }

        
    }

}

const getters = {}


 export default {

    state:state,
    mutations:mutations,
    actions:actions,
    getters:getters

 }