<template>
   <div class="retrieve-password-wrapper">
       <div class="hd"><van-nav-bar left-arrow safe-area-inset-top title="找回密码" @click-left="back"></van-nav-bar></div>
       <div class="bd">
           <van-form @submit="onSubmit" label-width="5em" validate-first>
            <van-field
                v-model="phone"
                type="tel"
                name="tel"
                label="手机号码"
                placeholder="请输入对应账号的手机号码"
                ref="tel"
                autocomplete="off"
            />  
            <van-field
                v-model="password"
                :type="newShow ? '' : 'password'"
                name="password"
                label="密码"
                right-icon="eye"
                placeholder="密码，最少六位"
                :class="{show:newShow}"
                @click-right-icon="newShow=!newShow"
                ref="password"
            />  
            <van-field
                v-model="passwordMore"
                :type="confirmShow ? '' : 'password'"
                name="passwordMore"
                label="确认密码"
                right-icon="eye"
                placeholder="确认密码"
                :class="{show:confirmShow}"
                @click-right-icon="confirmShow=!confirmShow"
                ref="passwordmore"
            />
            <van-field
                v-model="wxcode"
                label="短信验证码"
                class="wxcode"
                type="number"
                ref="codeFocus"
                placeholder="请输入短信验证码"
                autocomplete="off"
            >
            <template #button>
                <van-button size="small" type="primary" color="#00a0e4" @click.stop.prevent="getWxcodeN" :disabled="wxLock">{{wxCodeText}}</van-button>
            </template>
            </van-field>
            <div class="tips">为了保证您的账号安全，请自行设置密码，采用字母+数字等组合方式，密码长度应不小于8位，且不使用纯数字或纯字母,以及不能含有空格。</div>
            <van-button round block type="info" class="submit-btn" native-type="submit" :disabled="!btnDisabled">提交</van-button>
            </van-form>
       </div>
       <verify-code
        v-model="verifyCodeShow"
        @confirm="getCheckCode"
        ref="verifyCode"
        :loading="codeLoading"
        ></verify-code>
   </div>
</template>

<script>
import api from '@/api'
import backMixin from "@/mixins/back";
import wxcodeMixin from '@/mixins/wxcode'
import {isEqual,isPhone} from "@/utils";
import verifyCode from "@/components/common/verify-code";
export default {

    mixins:[backMixin,wxcodeMixin],
    components:{
        verifyCode
    },
    data(){

        return{

            password:'',

            passwordMore:'',

            wxcode:'',

            newShow:false,

            confirmShow:false,

            phone:'',

            verifyCodeShow:false,

            codeLoading: false

        }

    },

    computed:{

        btnDisabled(){

            return this.wxcode && this.password && this.passwordMore;

        }


    },



    methods:{

        onSubmit(){

            if(!this.phone){

                this.$notify({type:"danger",message:"手机号码不能为空"});

                this.$refs.tel.focus();

                return;

            }     
            
            if(!isPhone(this.phone)){

                this.$notify({type:"danger",message:"手机号码格式错误"});

                this.$refs.tel.focus();

                return;

            }

            //判断是否满足六位

            if(this.password.length <6){

                this.$notify({type:"danger",message:"密码最少六位"});

                this.$refs.password.focus();

                return;

            }

            //判断两次密码是否输入一致

            if(!isEqual(this.password,this.passwordMore)){

                this.$notify({type:"danger",message:"两次密码输入不一致"});

                this.$refs.passwordmore.focus();

                return false;

            }

            //微信验证码

            if(!this.wxcode){

                this.$notify({type:"danger",message:"微信验证码不能为空"});

                this.$refs.wxcode.focus();

                return false;

            }

            
            this.$toast.loading({

                message:'提交中...',

                duration:0

            })

            api.retrievePassword({

                options:{

                    password:this.password,

                    passworMore:this.passwordMore,

                    wxcode:this.wxcode,

                    phone:this.phone

                },

                success:(res)=>{

                    if(res.type == 200){
                        
                        this.$dialog.alert({

                            title:'密码修改成功',

                            message:'密码修改成功，将会在下一次登录启用'

                        }).then(()=>{

                            this.$router.back();

                        })


                    }else{

                        this.$dialog.alert({

                            title:'修改失败',

                            message:res.msg || '密码修改失败，请稍后重试'

                        }).then(()=>{})

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'修改失败',

                        message:'密码修改失败，提交异常，请稍后重试'

                    }).then(()=>{})


                },

                complete:()=>{

                    this.$toast.clear();

                }

            })


        },

        getWxcodeN(){
            if(!this.phone){
                this.$notify({type:"danger",message:"手机号码不能为空"});
                this.$refs.tel.focus();
                return;
            }     
            if(!isPhone(this.phone)){
                this.$notify({type:"danger",message:"手机号码格式错误"});
                this.$refs.tel.focus();
                return;
            }
            this.verifyCodeShow = true;
        },

        getCheckCode(verifyCode){
            this.codeLoading = true;
            this.getWxCode(api,{
                ...verifyCode,
                username:this.phone
            })
        }

    },

    activated(){

        //判断用户是否为登录状态

        if(this.$store.state.userInfo.ready){

            this.phone = this.$store.state.userInfo.phone;

        }

    }

}
</script>

<style  lang="less">
.retrieve-password-wrapper{

    background:#f6f6f6;

    height:100%;

    .van-field{

        &.wxcode{

            .van-field__label{

                line-height:33px;

            }

        }

    }

    .tips{

        padding: 15px;

        line-height:20px;

        color:#666666;

    }


    .submit-btn{

        margin:20px auto 0;

        width: 80%;

    }

}
</style>
