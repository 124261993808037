<template>
   <div class="my-wrapper">
       <div class="my-cover flex-box-column">
           <div class="my-wrapper-hd border_bottom">我的</div>
            <div class="my-wrapper-bd">
                <section class="my-wrapper-bd__column user-info" @click="goLink({name:'userInfo'})">
                        <div class="user-info-l">
                                <div class="user-info__avater" :style="avatar" :class="{avatar:!avatar || avatar === 'undefined',man:sex==1,woman:sex==2,secret:!avatar && sex == 0}"></div>
                                <div class="user-info__content">
                                    <p class="user-info_name">{{loginReady ? name :'登录/注册'}}</p>
                                    <p class="user-info_phone" v-if="loginReady">{{phone}}</p>
                                </div>
                        </div>
                        <div class="user-info-r"><van-icon name="arrow" class="user-info____more"/></div>
                </section>
                <section class="my-wrapper-bd__column">
                    <van-cell  title="报备休假" center class="value-center center-end" v-if="$role('report')">
                        <template>
                            <van-switch 
                            v-model="isVacation" 
                            :loading="switchLoading" 
                            :disabled="switchLoading" 
                            @change="setVacation" 
                            :size="switchSize"
                            :active-value="1"
                            :inactive-value="0"/>
                    </template>
                    </van-cell>
                    <!-- <van-cell  title="修改密码" center is-link :to="{name:'changePassword'}"/> -->
                    <van-cell  v-if="loginReady" :title="bindWx ? '解绑微信' : '绑定微信'" @click="getWxOpenId" label-class="error" center is-link :label="bindWx ? '' :'您还没有绑定微信，无法接收到微信通知'"/>
                    <van-cell  v-if="loginReady" title="更新权限" center is-link @click="updatePermission"/>
                    <van-cell  v-if="loginReady" title="切换公司" :value="companyName" center is-link @click="companyChange"/>
                </section>       
                <section class="my-wrapper-bd__column system-settings logout" v-if="loginReady">
                        <van-cell title="退出登录" center is-link @click="logOut" :title-style="{color:'#fd7171'}"/>
                </section>
            </div>
            <van-popup 
            v-model="showPicker" 
            position="bottom" 
            safe-area-inset-bottom
            close-on-popstate>
                <van-picker
                    show-toolbar
                    title="选择公司"
                    ref="recordPicker"
                    :columns="companyList"
                    @confirm="onConfirm"
                    @cancel="showPicker = false"
                    :default-index="companyDefaultIndex"
                />
            </van-popup>
       </div>
   </div>
</template>

<script>

import api from '@/api';
import {findIndex} from "@/utils";
import {pxToVw} from "@/utils";
export default {

    data(){
        return{
            showPicker:false,
            companyList:[],
            //是否开始请求

            switchLoading:false
        }
    },

    computed:{

        name(){

            return this.$store.state.userInfo.name;

        },   
        
        phone(){

            return this.$store.state.userInfo.phone;

        },     
        
        sex(){

            return this.$store.state.userInfo.sex;

        },   

        avatar(){

            return this.$store.state.userInfo.avatar && this.$store.state.userInfo.avatar !== 'undefined' ? {backgroundImage:`url(${this.$getImageUrl(this.$store.state.userInfo.avatar)})`} : '';

        },

        bindWx(){

            return this.$store.state.userInfo.bindWx;

        },

        loginReady(){

            return this.$store.state.userInfo.ready;

        },

        mainTitle(){

            return process.env.VUE_APP_SYSTEM_NAME == 'huimai' ? '慧麦' : '房小云'

        },

        companyId(){
            return this.$store.state.userInfo?.companyId;
        },
        companyName(){
            return this.$store.state.userInfo?.userCompany?.companyName || '';
        },
        companyDefaultIndex(){
            return findIndex(this.companyList,(el)=>{
                return el.value == this.companyId;
            })
        },
        
        //switch字体大小

        switchSize(){

            return pxToVw(20)+'vw';

        },
        
         //报备专员休假状态
        isVacation:{
            get(){
                return this.$store.state.userInfo?.vacation;
            },
            set(val){
                this.$store.commit('setUserData',{
                    key:'vacation',
                    value:val,
                    save:true
                });
            }
        },

    },

    methods:{

        goLink(path){

            this.$router.push(path);

        },

        logOut(){

            this.$dialog.confirm({

                title:'退出登录',

                message:'是否要退出登录状态',

                confirmButtonColor:"#00a0e4"

            }).then(()=>{

                this.$toast.loading({

                    message:'正在登出...',

                    duration:0,

                    forbidClick:true

                })

                api.logOut({

                    success:(res)=>{

                        if(res.type == 200){

                            this.$toast("退出成功");

                            this.$store.commit('resetUserData');

                            this.$nextTick(()=>{

                                this.$router.replace({name:"login"});

                            })

                        }else{

                            this.$dialog.alert({

                                title:'退出失败',

                                message:res.msg || "退出失败，请稍后重试"

                            }).then(()=>{})

                        }

                    },

                    error:(err)=>{

                        console.log(err);

                        this.$dialog.alert({

                            title:'退出失败',

                            message:"退出失败，请求异常，请稍后重试"

                        }).then(()=>{})

                    },

                    complete: ()=> {

                        this.$toast.clear();
                        
                    }

                })

                
            }).catch(()=>{})

        },
        
        //更新权限
        updatePermission(){

            this.$toast.loading({

                message:'正在更新...',

                forbidClick:true

            })

            api.getInitConfig({
    
                    success:(res)=>{
        
                        if(res.type == 200){
        
                            this.$dialog.alert({

                                title:'更新权限成功',

                                message:'更新权限成功',

                                confirmButtonColor:"#00a0e4"

                            }).then(()=>{})


                            this.$store.commit('initData',res.data);
                    
                        }else{
        
                            this.$dialog.alert({

                                title:'更新权限失败',

                                message:'更新权限失败，请稍后重试'

                            }).then(()=>{})
        
                        }
        
                    },
        
                    error:(err)=>{
        
                        console.log(err);

                        this.$dialog.alert({

                            title:'更新权限失败',

                            message:'更新权限失败，请稍后重试'

                        }).then(()=>{})
        
                    },

                    complete:()=>{

                        this.$toast.clear();

                    }
        
                })
        

        },

        //切换公司
        companyChange(){
            //保证最新数据，每次都去请求
              api.getCompanyList({
                    options:{
                        username:this.phone
                    },
                    success:(res)=>{
                        this.$toast.clear();
                        if(res.type == 200){
                            this.companyList = res.data.map(el=>{
                                return {
                                    text:el.companyName,
                                    value:el.id
                                }
                            });
                            this.showPicker = true;
                        }else{
                            console.log(res);
                            this.$toast(res.msg || '获取公司列表失败');
                        }
                    },
                    error:(err)=>{
                        this.$toast.clear();
                        this.$toast(err || '获取公司列表失败');
                    },
                })
        },

        //确认选择公司
        onConfirm(val){
            //当前公司
            if(val.value == this.companyId){
                this.$toast('已在当前公司');
            }else{
                this.$toast.loading({
                    message:'正在切换公司...',
                    duration:0,
                    forbidClick:true
                });

                api.selectCompany({
                    options:{
                        "companyId":val.value
                    },
                    success:(res)=>{
                        if(res.type == 200){
                            res.data.token = this.$store.state.userInfo.token;
                            this.$dialog.alert({
                                title:'切换成功',
                                message:`已切换至${res.data.userCompany?.companyName}`,
                                confirmButtonColor:"#00a0e4"
                            }).then(()=>{})
                            this.$store.commit('initData',res.data);
                        }
                    }, 
                    error:(err)=>{
                        console.log(err);
                    },
                    complete:()=>{
                        this.$toast.clear();
                    }
                })

            }
            this.showPicker =false;
        },

        getWxOpenId(){

            if(this.bindWx){

                this.$dialog.confirm({

                    title:"解绑微信",

                    message:'是否要解绑微信'

                }).then(()=>{

                    this.closeWx();

                }).catch(()=>{})

            }else{

                this.$toast.loading({

                    message:"正在绑定...",

                    duration:0,

                    forbidClick: true,

                });


                api.bindWx({

                    options:{

                        url:'https://'+window.location.host+this.$route.path+'?active=my'

                    },

                    success:(res)=>{

                        if(res.type == 200){

                            window.location.href=res.data;

                        }else{

                            this.$dialog.alert({

                                title:'绑定失败',

                                message:res.msg || "微信绑定失败，请求异常，请稍后重试"

                            }).then(()=>{})


                        }

                    },

                    error:(err)=>{

                        console.log(err);

                        this.$dialog.alert({

                            title:'绑定失败',

                            message:"微信绑定失败，请求异常，请稍后重试"

                        }).then(()=>{})

                    },

                    complete:()=>{

                        this.$toast.clear();

                    }

                })

            }

        },
        
        bindOpenId(code){

            this.$toast.loading({

                message:"正在绑定...",

                duration:0,

                forbidClick: true,

            });

            api.bindWxCode({

                options:{

                    code

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.$dialog.alert({

                            title:'绑定成功',

                            message:"微信绑定成功，请关注公众号，以便能够正常的接收到通知"

                        })

                        this.$store.commit('setUserData',{

                                key:'bindWx',

                                value:1,

                                save:true

                         });

                    }else{

                        this.$dialog.alert({

                            title:'绑定失败',

                            message:res.msg || "微信绑定失败，请求异常，请稍后重试"

                        }).then(()=>{})

                    }


                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'绑定失败',

                        message:"微信绑定失败，请求异常，请稍后重试"

                    }).then(()=>{})

                },

                complete:()=>{

                    this.$toast.clear();

                    this.$nextTick(()=>{

                        this.$router.replace({name:"home",params:{active:'my'}});

                    })


                }

            })

        },

        closeWx(){

            this.$toast.loading({

                message:'正在解除...',

                duration:0,

                forbidClick: true

            });

            api.closeWx({

                success:(res)=>{

                    if(res.type == 200){

                        this.$toast('微信解绑成功');

                        this.$store.commit('setUserData',{

                            key:'bindWx',

                            value:0,

                            save:true

                        })

                    }else{

                        this.$dialog.alert({

                            title:'解绑失败',

                            message:res.msg || '微信解绑失败，请稍后重试'

                        }).then(()=>{})

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$dialog.alert({

                        title:'解绑失败',

                        message:'微信解绑失败，请稍后重试'

                    }).then(()=>{})


                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

        },

         //切换值班状态

        setVacation(val){

            this.switchLoading = true;

            api.updateUserData({

                options:{

                    vacation:val

                },

                success:(res)=>{

                    if(res.type == '200'){

                        let vacation = parseInt(res.data.vacation);

                        this.$toast(`切换${vacation ? '休假' : '值班'}状态成功`);

                        this.$store.commit('setUserData',{

                            key:'vacation',

                            value:vacation,

                            save:true

                        });

                    }else{
                        
                        this.$toast(`切换${val ? '休假' :'值班'}状态失败，请稍后重试`);

                        this.$store.commit('setUserData',{

                            key:'vacation',

                            value:val ? 0 : 1,

                            save:true

                        });

                    }

                },

                error:(err)=>{

                    this.$toast(`切换${val ? '休假' : '值班'}状态失败，请稍后重试`+err);

                    this.$store.commit('setUserData',{

                        key:'vacation',

                        value:val ? 0 : 1,

                        save:true

                    });

                },
                complete:()=>{

                    this.switchLoading=false;

                }

            })

        }


    },


    activated(){
        //获取参数

        if(this.$route.query.code && this.$route.query.active =='my'){

            this.bindOpenId(this.$route.query.code);

        }

    }

}
</script>

<style lang="less">
.my-wrapper{

    background:#f6f6f6;

    height:100%;

    .my-cover{

        height:100%;

    }

    .my-wrapper-hd{

        height:50px;

        line-height:50px;

        text-align: center;

        font-size:16px;

        background:#fff;

    }

    .my-wrapper-bd{

        flex:1 1 auto;

        overflow: hidden;

        overflow-y:auto;

        .user-info{

            display: flex;

            justify-content: space-between;

            align-items: center;

            padding: 20px 10px 20px 16px;

            .user-info-l{

                display: flex;

                align-items: center;

                .user-info__avater{

                    width: 55px;

                    height:55px;

                    background-size:cover;

                    border-radius:50%;

                    background-repeat: no-repeat;

                    background-position: center center;

                }

                .user-info__content{

                    padding-left:12px;

                    .user-info_name{

                        font-size:16px;

                    }

                    .user-info_phone{

                        font-size:14px;

                        padding-top:10px;

                    }

                }

            }

            .user-info-r .user-info____more{

                font-size:26px;

                color:#a5a5a5;

            }

        }

        .toolbar{

            padding: 10px 0;

            background:#fff;

            .van-grid-item{

                height:60px;

                .van-grid-item__content{

                    background:#fcf8f0;

                    border-radius: 5px;

                    .item{

                        width: 100%;

                        height:100%;

                        display: flex;

                        align-items: center;

                        justify-content: center;

                        color:#aea790;

                        .type{

                            font-size:15px;

                        }

                        .icon{

                            font-size:22px;

                            margin-right:5px;

                            margin-top:-2px;

                        }

                    }

                }

                &:nth-child(2) .van-grid-item__content{

                    background: #f6f5fb;

                    .item{

                        color:#9ba5ad;

                    }

                }


                &:active{
                    
                    opacity: .6;

                }

            }

        }

        .system-settings{

            padding: 0;

            margin-top:15px;

            .van-cell{

                line-height:30px;

                .van-cell__title, .van-cell__value{

                    font-size:14px;

                }

            }

            &.logout{

                margin-bottom:15px;

            }

        }

    }


}
</style>
