<template>
   <div class="user-info-wrapper">
       <div class="user-info-cover flex-box-column">
           <div class="hd">
               <van-nav-bar left-arrow safe-area-inset-top title="个人信息" @click-left="back">
                </van-nav-bar>
           </div>
           <div class="bd">
                <van-cell title="设置头像" is-link class="upload-avatar-cover">
                        <div class="upload-avatar">
                            <van-uploader 
                            :after-read="afterRead"/>
                            <div class="avatar" :class="{man:!avatar && sex == 1,woman:!avatar && sex== 2,secret:!avatar && sex == 0}" :style="avatar ? {backgroundImage:'url('+$getImageUrl(avatar)+')'} :'' "></div>
                        </div>
                    </van-cell>      
                <van-cell-group class="item">
                    <van-cell title="姓名" :value="name" is-link :to="{path:'/user/info/set/name'}"/>
                    <van-cell title="手机" :value="phone"/>
                    <van-cell title="性别" :value="sex == 1 ? '男': sex == 2 ? '女' : '保密' " is-link @click="showPicker=true"/>
                </van-cell-group>          
                <van-cell-group class="item">
                    <van-cell title="所属部门" :value="departmentName"/>
                    <van-cell title="当前公司" :value="companyName"/>
                </van-cell-group>
           </div>
       </div>
       <van-popup 
       v-model="showPicker" 
       position="bottom" 
       safe-area-inset-bottom
       close-on-popstate>
        <van-picker
            show-toolbar
            title="选择性别"
            ref="recordPicker"
            :columns="sexList"
            @confirm="onConfirm"
            @cancel="showPicker = false"
            :default-index="sex"
        />
        </van-popup>
   </div>
</template>

<script>
import api from "@/api";
import imageUploadMixin from "@/mixins/imageUpload";
import backMixin from "@/mixins/back";
export default {

    mixins:[imageUploadMixin,backMixin],

    data(){

        return{

            showPicker:false,

            sexList:[{text:'保密',value:0},{text:"男",value:1},{text:'女',value:2}]

        }

    },

    computed:{

        name(){

            return this.$store.state.userInfo.name;

        },   
        
        phone(){

            return this.$store.state.userInfo.phone;

        },   
        
        resume(){

            return this.$store.state.userInfo.resume;

        },

        subdistributorName(){

            return this.$store.state.userInfo.subdistributorName;

        },

        sex(){

            return this.$store.state.userInfo.sex;

        },

        avatar(){

            return this.$store.state.userInfo.avatar;

        },

        departmentName(){
            return this.$store.state.userInfo?.departmentName || '';
        },

        companyName(){
            return this.$store.state.userInfo?.userCompany?.companyName || '';
        }

    },

    methods:{

        afterRead(file){

            const _check = this.beforeThumbUpload(file.file);

            if(!_check) return;

            const formData = new FormData();         
            
            formData.append('file', file.file);

            api.upload({

                options:formData,

                success:(res)=>{

                    if(res.type == 200){

                        this.$toast.loading({

                            message:'正在上传...',

                            duration:0,

                            forbidClick:true

                        })

                        api.updateUserData({

                            options:{

                                avatar:res.data.url

                            },

                            success:(_res)=>{

                                if(_res.type == 200){

                                    this.$store.commit('setUserData',{key:'avatar',value:res.data.url,save:true});

                                }else{

                                    this.$notify({type:"danger",message:_res.msg || '头像修改失败'});

                                }

                            },

                            error:(err)=>{

                                console.log(err);

                                this.$notify({type:"danger",message:'头像修改失败'});

                            },

                            complete:()=>{

                                this.$toast.clear();

                            }


                        })

                    }else{

                        this.$notify({ type: 'danger', message: res.msg || '图片上传失败，请稍后重试' });

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$notify({ type: 'danger', message:'图片上传失败，请检查网络'});

                }

            })

        },

        onConfirm(val){

            this.$toast.loading({

                message:'正在提交...',

                duration:0

            });


            api.updateUserData({

                options:{

                    sex:val.value

                },

                success:(res)=>{

                    if(res.type == 200){

                        this.$store.commit('setUserData',{key:'sex',value:res.data.sex,save:true});



                    }else{

                        this.$notify({type:"danger",message:res.msg || '修改性别失败，请稍后重试'});

                        this.defaultIndex = this.defaultIndex == 1 ? 2 : 1;

                    }

                },

                error:(err)=>{

                    console.log(err);

                    this.$notify({type:"danger",message:'修改性别失败，提交异常，请稍后重试'});

                    this.defaultIndex = this.defaultIndex == 1 ? 2 : 1;

                },

                complete:()=>{

                    this.$toast.clear();

                }

            })

            this.showPicker=false;

        }

    }

}
</script>

<style lang="less">
.user-info-wrapper{

    height:100%;

    .user-info-cover{

        height:100%;

        .bd{

            flex:1;

            height:0;

            overflow: hidden;

            overflow-y:auto;

            background: #f6f6f6;

            .item{

                margin-top:10px;

            }

        .upload-avatar-cover{

        line-height:40px;

        .van-cell__value{

            width: 180px;

            overflow: visible;

        }

        .van-cell__left-icon, .van-cell__right-icon{

            line-height:40px;

            height:40px;

        }



        
        .upload-avatar{

            width: 100%;

            height:100%;

            position: relative;

            .van-uploader{

                position: absolute;

                width: 260px;

                height:100%;

                z-index:20;

                right:44px;

                top:0;

            }

            .avatar{

                position: absolute;

                right:0;

                top:0;

                width: 40px;

                height:40px;

            }

            .van-uploader{
                
                opacity: 0;

                position: relative;

                z-index:10;

            }

            .van-uploader__wrapper,.van-uploader__upload{

                width: 100%;

                height:100%;

            }

        }

    }

        }

    }

   


}

</style>
