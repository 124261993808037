const router = [{

    path:"/record/audit-list",

    name:'recordAuditList',

    component:() => import('@/views/management/recordList'),

    meta:{

            lv:3,

            //标记为懒加载组件

            module:true,

        }

    }

]




export default router;