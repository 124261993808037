
/**
 * 
 *渠道管理路由，所有渠道相关写在此处
 * 
 *  
 * 
 * 
 * 
 * 注：此模块路由全部为异步路由
 * 
 * 
 * 
 */

const PERMISSION = 'CHANNELSTATISTICS';


const router = [

    {

        path:'/channel/statistics/index',

        name:'channel_statistics_index',

        component:() => import(/* webpackChunkName: "channel-group" */ '@/views/channel/statistics_index'),

        meta:{

            lv:2,

            module:true,

            permission:PERMISSION

        }

    },
    {

        path:'/channel/statistics/detail/:type',

        name:"channelStatisticsDetail",

        component:() => import(/* webpackChunkName: "channel-detail-group" */ '@/views/channel/statistics_detail'),

        meta:{

            lv:5,

            module:true,

            permission:PERMISSION

        }


    }

];

export default router;