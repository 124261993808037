
/**
 * 
 * 渠道报备列表路由，所有渠道报备列表相关写在此处
 * 
 *  
 * 
 * 
 * 
 * 注：此模块路由全部为异步路由
 * 
 * 
 * 
 */

const PERMISSION = 'EXTENSIONRECORD';


const router = [

    {

        path:'/estate/development/record/list',

        name:'estate_development_record_list',

        component:() => import(/* webpackChunkName: "estate_development_record_list" */ '@/views/estate_development/record_list'),

        meta:{

            lv:2,

            module:true,

            permission:PERMISSION

        }

    }

];

export default router;