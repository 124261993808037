import CryptoJS from 'crypto-js';


const fky_ds_t = 'c545459869b81ac0';


//解密方法

const Decrypt = (data)=>{

    var bytes  = CryptoJS.AES.decrypt(data, fky_ds_t);

    var _data = bytes.toString(CryptoJS.enc.Utf8);

    var decryptedData = _data ? JSON.parse(bytes.toString(CryptoJS.enc.Utf8)) : {};
    
    return decryptedData

}

//加密方法

const Encrypt = (data)=>{

    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data),fky_ds_t).toString();

    return ciphertext;

}


export{

    Decrypt,
    Encrypt

}